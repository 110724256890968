import React from "react"
import styled from "styled-components"

import GlobalStateProvider from "../context/provider"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentWrapper from "../styles/contentWrapper"

const NotFoundPage = () => {
  const globalState = {
    isIntroDone: true,
    darkMode: false,
  }

  return (
    <GlobalStateProvider initialState={globalState}>
      <Layout>
        <SEO
          title="404: Not found"
          meta={[{ name: "robots", content: "noindex" }]}
        />
          <div style={{height:'calc(70vh)' , marginTop:-25, display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
            <h1 data-testid="heading">NOT FOUND</h1>

            <img src='/images/404.gif' width='200' height='200' />
            <p>一望無際的荒原</p>
          </div>
      </Layout>
    </GlobalStateProvider>
  )
}

export default NotFoundPage
